import { css, cx } from "@@panda/css";
import { Box, HStack } from "@@panda/jsx";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Button } from "components/main/Button";
import { PropsWithChildren } from "react";
import { Drawer } from "vaul";

import { DialogCtx } from "../useDialogCtx";
import { drawerStyles, modalStyles } from "./styles";

type BaseProps = DialogCtx & PropsWithChildren;

interface Props extends BaseProps {
  testId?: string;
  title: string;
  handleClose: () => void;
}

export function DrawerWrap({
  children,
  title,
  testId,
  handleClose,
  isForceOpen,
}: Props) {
  const closeBtn = (
    <Button variant="link" as="button" aria-label="Close" onClick={handleClose}>
      <XMarkIcon color={"white"} width="1.5rem" />
    </Button>
  );

  return (
    <Drawer.Portal>
      <Drawer.Overlay className={drawerStyles.overlay} />
      <Drawer.Content
        aria-describedby={undefined}
        className={drawerStyles.content}
        data-testid={testId}
        onInteractOutside={handleClose}
      >
        <HStack
          w="full"
          justifyContent="space-between"
          alignItems="center"
          pb={title ? "l" : "0"}
        >
          {title ? (
            <Drawer.Title className={drawerStyles.title}>{title}</Drawer.Title>
          ) : null}

          {!isForceOpen ? (
            title ? (
              closeBtn
            ) : (
              <Box
                className={cx(
                  modalStyles.iconWrap,
                  css({
                    transition: ".2s background ease",
                    _hover: {
                      bgColor: "grey.minecraft",
                    },
                  })
                )}
              >
                {closeBtn}
              </Box>
            )
          ) : null}
        </HStack>
        <Box overflow="auto">{children}</Box>
      </Drawer.Content>
    </Drawer.Portal>
  );
}
