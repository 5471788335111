export enum Keys {
  Authorization = "authorization",
  OdinFeeWarning = "odin_fee_warning",
  BackOnline = "back_online",
}

function get(key: Keys) {
  if (typeof window === "undefined") return;
  return window.localStorage.getItem(key);
}

function set(key: Keys, value: string) {
  if (typeof window === "undefined") return;
  return window.localStorage.setItem(key, value);
}

function clean(key: Keys) {
  if (typeof window === "undefined") return;
  return window.localStorage.removeItem(key);
}

export const storage = { clean, get, set };
